<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<DefaultLayout>
		<ofs-panel v-if="isLoading">
			<Loader />
		</ofs-panel>
		<ofs-panel v-else class="AccountView">
			<content-header :title="pageTitle" :breadcrumbs="breadcrumbs" no-padding class="mb-3" />
			<section class="AccountView_container">
				<b-tabs>
					<b-tab :title="$t('Details')" @click="resetForm">
						<b-form class="mt-3">
							<b-row>
								<b-col>
									<of-multi-select
										name="status"
										:label="$t('Account Status')"
										:horizontal="false"
										:allow-clear="false"
										data-test-id="accountStatus"
										track-by="value"
										:options="statusOptions"
										:placeholder="$t('Status')"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<of-toggle
										name="disabled"
										data-test-id="AccountDisabledToggle"
										:right-side-label="$t('Disabled')"
										:description="descriptions.disabled"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<of-toggle
										name="settings.psp"
										data-test-id="AccountSettingsPSPToggle"
										:right-side-label="$t('Print Service Provider')"
										:description="descriptions.psp"
									/>
								</b-col>
							</b-row>
							<b-row v-if="isPsp">
								<b-col>
									<Accordion
										class="mt-2"
										:title="$t('Print Service Provider Configuration')"
										inline
										expanded
									>
										<of-multi-select
											name="plan"
											:label="$t('Account Type (plan)')"
											:horizontal="false"
											:allow-clear="false"
											data-test-id="accountType"
											track-by="value"
											:options="planOptions"
											:placeholder="$t('Plan')"
											:description="descriptions.plan"
										/>
									</Accordion>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<of-multi-select
										name="globalCatalogAccountId"
										:label="$t('Catalog Source Account')"
										:horizontal="false"
										:allow-clear="true"
										data-test-id="globalCatalogAccountId"
										track-by="value"
										:options="catalogSourceAccountOptions"
										:placeholder="$t('Catalog Source Account')"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<of-multi-select
										name="settings.autoCompleteBatchDays"
										:label="$t('Batch Cleaner Days')"
										:allow-clear="false"
										track-by="value"
										:options="autoCompleteOptions"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<h3 class="mt-2">{{ $t('Auth API Settings') }}</h3>
									<of-multi-select
										name="authAccountTypes"
										multiple
										:label="$t('Types')"
										track-by="value"
										:options="typesOptions"
										:description="$t('Updates auth API account types field aka kong ui')"
									/>
								</b-col>
							</b-row>
						</b-form>
						<div class="AccountView__actions">
							<b-button
								variant="primary"
								class="AccountView__submit"
								:disabled="!canSubmit"
								@click.prevent="onSaveAccount"
							>
								{{ $t('Save') }}
							</b-button>
						</div>
					</b-tab>
					<b-tab :title="$t('Features')">
						<div class="d-flex flex-column">
							<ul class="FeatureList">
								<Segment
									v-for="s in segments"
									:key="s.key"
									:segment-summary="s"
									:account-id="id"
									:update-segment="segmentUpdated"
								/>
								<li
									v-for="feature in features"
									:key="feature.key"
									class="FeatureList__item FeatureListItem"
								>
									<label class="FeatureListItem__title" :for="`feature__${feature.key}`">{{
										feature.name
									}}</label>
									<p class="FeatureListItem__description">{{ feature.description }}</p>
									<p v-if="!feature.enabled">
										<ofs-badge text="No Longer Editable" />
									</p>
									<b-form-checkbox
										:id="`feature__${feature.key}`"
										v-model="featureStatus[feature.key]"
										:disabled="!feature.enabled"
										class="FeatureListItem__checkbox"
										@input="featureChanged(feature.key)"
									/>
								</li>
							</ul>
							<div class="AccountView__actions">
								<b-button
									key="saveFeatures"
									v-b-modal.modalConfirm
									variant="primary"
									class="AccountView__submit"
									:disabled="featuresDisabled"
								>
									{{ $t('Save') }}
								</b-button>
							</div>
						</div>
					</b-tab>
					<b-tab :title="$t('History')">
						<section v-if="isLoading">
							<Loader />
						</section>
						<list-table
							v-else
							class="mt-2"
							:table-title="$t('Logs')"
							:items="logs"
							:fields="fields"
							:total-items="logCount"
							:per-page="perPage"
							:current-page="currentPage"
							:is-busy="isBusy"
							:page-position-prefix="$t('Showing')"
							:page-position-join="$t('of')"
							hover
						>
							<template #cell(createdAt)="{ item: { createdAt } }">
								{{ createdAt | niceDate }}
							</template>
							<template #cell(description)="{ item }">
								<div v-if="item.changes.length">
									<div v-for="event of item.changes" :key="event" class="mb-1">{{ event }}</div>
								</div>
								<div v-else>
									{{ item.description }}
								</div>
							</template>
							<template slot="empty" slot-scope="{}">
								<span>
									<i>{{ $t('No data') }}</i>
								</span>
							</template>
							<template slot="next-text" slot-scope="{}">
								{{ $t('Next') }}
							</template>
							<template slot="prev-text" slot-scope="{}">
								{{ $t('Prev') }}
							</template>
						</list-table>
					</b-tab>
					<b-tab :title="$t('Tools')">
						<section>
							<h1 class="mt-2">{{ $t('Reassign Device') }}</h1>
							<b-form class="mt-3">
								<b-row>
									<b-col lg="6">
										<b-form-group :label="$t('Old Device ID')" data-test-id="quantityReprint">
											<b-form-input
												v-model="oldDeviceId"
												:label="$t('Name')"
												:state="oldDeviceId && oldDeviceId.length === 24"
												name="oldDevice"
												:formatter="formatDeviceId"
												data-test-id="oldDeviceInput"
											/>
										</b-form-group>
									</b-col>
									<b-col lg="6">
										<b-form-group :label="$t('New Device ID')" data-test-id="quantityReprint">
											<b-form-input
												v-model="newDeviceId"
												:label="$t('Name')"
												:state="newDeviceId && newDeviceId.length === 24"
												:formatter="formatDeviceId"
												name="newDevice"
												data-test-id="newDeviceInput"
											/>
										</b-form-group>
									</b-col>
								</b-row>
								<div class="AccountView__actions">
									<b-row>
										<b-button
											:disabled="!canReassign"
											class="ml-auto mr-2"
											variant="primary"
											@click="reassignDevice"
										>
											{{ $t('Reassign') }}</b-button
										>
									</b-row>
								</div>
							</b-form>
						</section>
						<section>
							<h1 class="mt-2">{{ $t('Sync Sub-Batch files') }}</h1>
							<b-form class="mt-3">
								<b-row>
									<b-col lg="6">
										<b-form-group :label="$t('Sub-Batch Id')" data-test-id="subBatchIdGroup">
											<b-form-input
												v-model="subBatchId"
												:label="$t('Sub-Batch Id')"
												:state="subBatchId && canSyncSubBatch"
												name="subBatchId"
												:formatter="formatDeviceId"
												data-test-id="subBatchIdInput"
											/>
										</b-form-group>
									</b-col>
								</b-row>
								<div class="AccountView__actions">
									<b-row>
										<b-button
											:disabled="!canSyncSubBatch"
											class="ml-auto mr-2"
											variant="primary"
											@click="syncSubBatch"
										>
											{{ $t('Sync live Sub-Batch') }}
										</b-button>
										<b-button class="ml-auto mr-2" variant="danger" @click="showSyncModal">
											{{ $t('Sync Files from all not synced Sub-Batches') }}</b-button
										>
									</b-row>
								</div>
							</b-form>
						</section>
					</b-tab>
				</b-tabs>
			</section>
			<b-modal
				id="modalConfirm"
				:ok-title="$t('Save')"
				:cancel-title="$t('Discard Changes')"
				title="Update Features"
				@ok="saveFeatures"
				@cancel="cancel"
			>
				<p>{{ $t('Are you sure you want to update your features?') }}</p>
				<ul class="FeatureConfirmList">
					<li v-for="key in featureKeys" :key="key" class="FeatureConfirmList__item">
						<code class="FeatureConfirmList__key">{{ key }}</code>
					</li>
				</ul>
			</b-modal>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import {
	Accordion,
	OfsPanel,
	OfsBadge,
	withForm,
	ContentHeader,
	OfFormCheckbox,
	OfMultiSelect,
	OfToggle,
	ListTable
} from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import DefaultLayout from '../../components/DefaultLayout';
import Loader from '../../components/Loader';
import { displayError } from '../../lib/helpers';
import isCustomerServiceTeamMixin from '../../mixins/isCustomerServiceTeamMixin';
import { confirmModalMixin } from '../../mixins/confirmModalMixin';
import tableMixin from '../../mixins/tableMixin';
import { dateFormatMixin } from '../../mixins/dateFormatMixin';
import Segment from './Segment';

const formName = 'editAccount';

export default {
	components: {
		Accordion,
		ContentHeader,
		Loader,
		OfsPanel,
		OfsBadge,
		DefaultLayout,
		OfMultiSelect,
		OfToggle,
		Segment,
		ListTable
	},
	mixins: [isCustomerServiceTeamMixin, withForm(formName), tableMixin, dateFormatMixin(), confirmModalMixin()],
	data() {
		return {
			isLoading: true,
			// Enabled state of each feature
			featureStatus: {},
			// Initial enabled state of each feature
			initialFeatureStatus: {},
			// Features that have been changed
			updatedFeatures: {},
			statusOptions: [
				{ text: this.$t('Active'), value: 'active' },
				{ text: this.$t('Inactive'), value: 'inactive' },
				{ text: this.$t('Test'), value: 'test' },
				{ text: this.$t('Demo'), value: 'demo' }
			],
			planOptions: [
				{ text: this.$t('Pro'), value: 'pro' },
				{ text: this.$t('Lite'), value: 'lite' }
			],
			typesOptions: [
				{ text: this.$t('Brand'), value: 'brand' },
				{ text: this.$t('PSP'), value: 'psp' }
			],
			descriptions: {
				disabled: this.$t(
					'Disables an account from printing or downloading files. Orders will still be received as normal.'
				),
				psp: this.$t(
					// eslint-disable-next-line max-len
					'Configure account as a Print Service Provider, they will be able to receive orders. Disable this flag disabled for Brands.'
				),
				plan: this.$t(
					// eslint-disable-next-line max-len
					'Changing the account type will require checking that all orders on the account have been shipped and that subbatches are closed.'
				)
			},
			segments: [
				{
					key: 'siteflow-3d',
					title: this.$t('Site Flow - 3D'),
					description: this.$t(
						'Enabling this feature will enable 3D related functionality for this account on Site Flow.'
					)
				},
				{
					key: 'site-flow-new-ui-production',
					title: this.$t('Site Flow - New Production UI'),
					description: this.$t(
						'Enabling this feature will enable the new Production UI section in Site Flow. Customers will not be able to use the old Production UI unless this is disabled.'
					)
				}
			],
			initialSegmentStatus: {},
			segmentStatus: {},
			updatedSegments: {},
			autoCompleteOptions: [
				{ text: '30', value: 30 },
				{ text: '60', value: 60 },
				{ text: '90', value: 90 },
				{ text: '120', value: 120 }
			],
			// logs table
			sort: {
				createdAt: -1
			},
			isBusy: false,
			fields: [
				{ key: 'createdAt', label: this.$t('Date') },
				{ key: 'description', label: this.$t('Event') },
				{ key: 'userEmail', label: this.$t('User') }
			],
			oldDeviceId: '',
			newDeviceId: '',
			subBatchId: ''
		};
	},
	computed: {
		...mapGetters({
			account: 'controlCentreAccount/controlCentreAccount',
			authAccount: 'controlCentreAccount/authAccount',
			features: 'controlCentreFeature/features',
			logs: 'controlCentreLog/logs',
			logCount: 'controlCentreLog/count',
			accountlinks: 'accountlink/allLinks'
		}),
		id() {
			return this.$route.params.id;
		},
		accountName() {
			return _.get(this.account, 'name');
		},
		companyName() {
			return _.get(this.account, 'settings.companyName');
		},
		pageTitle() {
			if (this.companyName) {
				return `${this.accountName} (${this.companyName})`;
			} else {
				return this.accountName;
			}
		},
		breadcrumbs() {
			return [
				{
					text: this.$t('Accounts /'),
					to: {
						name: 'accounts.list'
					}
				},
				{
					text: this.pageTitle,
					active: true
				}
			];
		},
		featuresChanged() {
			return Object.keys(this.updatedFeatures).length > 0;
		},
		segmentsChanged() {
			return Object.keys(this.updatedSegments).length > 0;
		},
		featuresDisabled() {
			return !this.featuresChanged && !this.segmentsChanged;
		},
		featureKeys() {
			return [..._.map(this.updatedFeatures, f => f.key), ..._.map(this.updatedSegments, f => f.key)];
		},
		isPsp() {
			return !!this?.formData?.settings?.psp;
		},
		sortedFeatures() {
			return _.sortBy(this.features, 'name');
		},
		canSubmit() {
			return this.formState.dirty && !this.formState.invalid;
		},
		canReassign() {
			return this.oldDeviceId.length === 24 && this.newDeviceId.length === 24;
		},
		canSyncSubBatch() {
			return this.subBatchId.length === 24;
		},
		catalogSourceAccountOptions() {
			return this.accountlinks.map(link => ({
				text: link.accountIdA === this.id ? link.accountNameB : link.accountNameA,
				value: link.accountIdA === this.id ? link.accountIdB : link.accountIdA
			}));
		}
	},
	watch: {
		id: {
			immediate: true,
			handler: 'fetchData'
		},
		features: {
			immediate: true,
			handler(features) {
				this.initialFeatureStatus = {};
				this.featureStatus = {};
				features.forEach(feature => {
					if (feature.defaultValue) {
						this.featureStatus[feature.key] = !feature.accountsDisabled.includes(this.id);
					} else {
						this.featureStatus[feature.key] = feature.accountsEnabled.includes(this.id);
					}
				});
				this.initialFeatureStatus = _.merge({}, this.featureStatus);
			}
		}
	},
	methods: {
		...mapActions({
			findAccount: 'controlCentreAccount/findById',
			getAuthAccount: 'controlCentreAccount/getAccount',
			updateAccount: 'controlCentreAccount/updateAccount',
			updateAuthAccount: 'controlCentreAccount/updateAuthAccount',
			updateAccountGlobalCatalog: 'autoflowAccount/updateAccountGlobalCatalog',
			reassignDevices: 'accountTools/reassignDevices',
			syncNotSyncedSubBatches: 'accountTools/syncNotSyncedSubBatches',
			findFeatures: 'controlCentreFeature/find',
			updateFeature: 'controlCentreFeature/update',
			updateSegment: 'controlCentreSegment/update',
			findLogs: 'controlCentreLog/find',
			fetchAllLinks: 'accountlink/fetchAllLinks'
		}),
		// Resets local state of filters
		reset() {
			this.featureStatus = _.merge({}, this.initialFeatureStatus);
			this.segmentStatus = _.merge({}, this.initialSegmentStatus);
			this.updatedFeatures = {};
			this.updatedSegments = {};
		},
		async fetchData() {
			this.isLoading = true;
			try {
				if (this.id) {
					await this.findAccount({ id: this.id });
					await this.getAuthAccount(this.id);
					await this.findFeatures({
						query: {
							query: {
								$limit: 1000,
								tag: 'customer-success'
							}
						}
					});
					this.reset();
					this.resetForm();
					await this.loadLogs();
					await this.fetchAllLinks({ query: { name: this.accountName, active: true, pagesize: 100 } });
				}
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
				this.$router.push({ name: 'admin.fulfilments' });
			} finally {
				this.isLoading = false;
			}
		},
		resetForm() {
			const formFields = [
				'status',
				'disabled',
				'plan',
				'settings.psp',
				'settings.autoCompleteBatchDays',
				'globalCatalogAccountId'
			];
			this.initFormData({ ..._.pick(this.account, formFields), authAccountTypes: this.authAccount.types });
		},
		async featureChanged(key) {
			const updatedFeatures = this.updatedFeatures;

			if (this.featureStatus[key] === this.initialFeatureStatus[key]) {
				// If new value is the same as the initial, remove from updatedFeatures
				delete updatedFeatures[key];
			} else {
				// If the feature has been changed, add it to updatedFeatures
				updatedFeatures[key] = this.features.find(feature => feature.key === key);

				// If feature is enabled
				if (this.featureStatus[key]) {
					// Add accountId to accountsEnabled
					if (!updatedFeatures[key].accountsEnabled.includes(this.id)) {
						updatedFeatures[key].accountsEnabled.push(this.id);
					}

					// Remove accountId from accountsDisabled
					updatedFeatures[key].accountsDisabled = updatedFeatures[key].accountsDisabled.filter(
						id => id !== this.id
					);
				} else {
					// Add accountId to accountsDisabled
					if (!updatedFeatures[key].accountsDisabled.includes(this.id)) {
						updatedFeatures[key].accountsDisabled.push(this.id);
					}

					// Remove accountId from accountsEnabled
					updatedFeatures[key].accountsEnabled = updatedFeatures[key].accountsEnabled.filter(
						id => id !== this.id
					);
				}
			}

			this.updatedFeatures = _.merge({}, updatedFeatures);
		},
		cancel() {
			this.reset();
		},
		async saveFeatures() {
			const promises = [];

			_.forEach(this.updatedFeatures, feature => {
				promises.push(
					this.updateFeature({
						id: feature.key,
						data: feature
					})
				);
			});

			_.forEach(this.updatedSegments, segment => {
				promises.push(
					this.updateSegment({
						id: segment.key,
						data: segment
					})
				);
			});

			try {
				await Promise.all(promises);
				await this.fetchData();
				this.$notify({
					type: 'success',
					text: 'Features on this account have been updated successfully'
				});
			} catch (err) {
				const message = displayError(err);
				this.$notify({ type: 'error ', text: message });
			}
		},
		async onSaveAccount() {
			if (this.formData.settings.psp !== this.account.settings.psp || this.formData.plan !== this.account.plan) {
				this.$bvModal
					.msgBoxConfirm(
						this.$t(
							'Changing the account type or plan requires that all orders on the account have been shipped and that subbatches are closed. Are you sure that this has been done?'
						),
						{
							title: this.$t('Confirm Change'),
							size: 'sm',
							centered: true,
							okTitle: this.$t('Confirm'),
							cancelTitle: this.$t('Cancel')
						}
					)
					.then(value => {
						if (value) {
							this.saveAccount();
						}
					});
			} else {
				this.saveAccount();
			}
		},
		async saveAccount() {
			try {
				await this.updateAccount({ id: this.id, data: { ...this.formData, authAccountTypes: undefined } });
				await this.updateAuthAccount({ id: this.id, data: { types: this.formData.authAccountTypes } });
				await this.updateAccountGlobalCatalog({
					id: this.id,
					data: { globalCatalogAccountId: this.formData.globalCatalogAccountId }
				});
				await this.fetchData();
				this.$notify({
					type: 'success',
					text: 'Account has been updated successfully'
				});
			} catch (err) {
				const message = displayError(err);
				this.$notify({ type: 'error ', text: message });
			}
		},
		segmentUpdated(segment, value, initialUpdate) {
			// If initial update, update initial segment value
			if (initialUpdate) {
				this.initialSegmentStatus[segment.key] = value;
			}

			// Update segment status
			this.segmentStatus[segment.key] = value;

			const updatedSegments = this.updatedSegments;

			if (this.segmentStatus[segment.key] === this.initialSegmentStatus[segment.key]) {
				// If new value is the same as the initial, remove from updatedSegments
				delete updatedSegments[segment.key];
			} else {
				// If the segment has been changed, add it to updatedSegments
				updatedSegments[segment.key] = segment;

				// If feature is enabled
				if (this.segmentStatus[segment.key]) {
					// Add accountId to accountsEnabled
					if (!updatedSegments[segment.key].accountsEnabled.includes(this.id)) {
						updatedSegments[segment.key].accountsEnabled.push(this.id);
					}
				} else {
					// Remove accountId from accountsEnabled
					updatedSegments[segment.key].accountsEnabled = updatedSegments[segment.key].accountsEnabled.filter(
						id => id !== this.id
					);
				}
			}

			this.updatedSegments = _.merge({}, updatedSegments);
		},
		async loadLogs() {
			try {
				const query = {
					query: {
						$limit: this.perPage,
						$skip: this.perPage * (this.currentPage - 1),
						$sort: this.sort,
						$where: {
							entity: 'account',
							entityId: this.id
						}
					}
				};

				this.findLogs({ query });
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			} finally {
				this.isBusy = false;
			}
		},
		formatDeviceId(id) {
			return id.substring(0, 24);
		},
		resetDeviceReassign() {
			this.oldDeviceId = '';
			this.newDeviceId = '';
		},
		async reassignDevice() {
			try {
				await this.reassignDevices({
					affectedAccountId: this.id,
					oldDeviceId: this.oldDeviceId,
					newDeviceId: this.newDeviceId
				});
				await this.fetchData();
				this.resetDeviceReassign();
				this.$notify({
					type: 'success',
					text: this.$t('Device has been reassigned successfully')
				});
			} catch (err) {
				const message = displayError(err);
				this.$notify({ type: 'error ', text: message });
			}
		},
		async showSyncModal() {
			await this.confirm({
				title: this.$t('Sync all not synced Sub-Batches'),
				message: (
					<div>
						<div v-t="Sync all live Sub-Batches files from that account that are not synced." />
						<div v-t="This process will take a while and might consume resources." />
						<div v-t="Please wait some minutes after triggering this action again." />
						<div v-t="Are you sure you want to proceed?" />
					</div>
				),
				type: 'warning',
				okVariant: 'danger',
				onOk: async () => {
					await this.syncSubBatches();
				}
			});
		},
		async syncSubBatches(id = null) {
			const payload = {
				affectedAccountId: this.id
			};
			if (id) payload.subBatches = [id];

			try {
				const result = await this.syncNotSyncedSubBatches(payload);
				this.$notify({ type: 'success ', text: result.data });
			} catch (err) {
				const message = displayError(err);
				this.$notify({ type: 'error ', text: message });
			}
		},
		async syncSubBatch() {
			await this.syncSubBatches(this.subBatchId);
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.AccountView {
	max-width: 1200px;

	.ContentHeader {
		&-title {
			text-transform: none;
		}
	}

	&__actions {
		margin-top: 20px;
		padding-top: 20px;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
}

.FeatureList {
	list-style: none;
	margin: 0;
	padding: 0;

	&__item {
		padding: 0;
		margin: 20px 0 0;
	}
}

.FeatureListItem {
	position: relative;
	padding-left: 25px;

	&__title {
		margin: 0 0 5px;
		padding: 0;
		line-height: 20px;
		font-weight: 600;
		font-size: 16px;
	}

	&__description {
		margin: 0;
	}

	&__checkbox {
		position: absolute;
		top: 0;
		left: 0;
	}

	&__loader {
		position: absolute;
		top: 0;
		left: 0;
		min-height: 20px;
		width: 17px;
		height: 17px;
	}
}

.FeatureConfirmList {
	margin: 0;
	padding: 0;
	list-style: none;

	&__item {
		margin: 0;
		padding: 0;
	}

	&__key {
		margin: 0;
	}
}
</style>

export default {
    async updateAccount({ dispatch }, { id, data }) {
        return dispatch('request', {
            method: 'PUT',
            path: `accounts/${id}`,
            payload: data
        });
    },
    async getAccount({ dispatch, commit }, id) {
        const response = await dispatch('request', { method: 'GET', path: `auth/accounts/${id}` });
        commit('SET_AUTH_ACCOUNT', response?.data);
        return response?.data;
    },
    async updateAuthAccount({ dispatch }, { id, data }) {
        return dispatch('request', { method: 'PUT', path: `auth/accounts/${id}`, payload: data });
    }
};
